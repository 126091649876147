function getTableHeight(table, route) {
   console.log(table.clientHeight - 50)
   console.log(route)
   if (table.clientHeight && table.clientHeight > 0) {
      return table.clientHeight-50
   } else {
      if (route == 'client') {
         return 400
      } else {
         return 600
      }
   }

}

export default getTableHeight;