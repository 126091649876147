/**
 * {常量名、函数名}
 */
import Vue from 'vue';
import http from './http';
import axios from "axios";
import md5 from "js-md5";
import Router from 'vue-router'
/**
 * 接口封装类
 */
/**
 * 公共调用方法
 * @param opts
 * @returns {Promise<unknown>}
 */

/**
 *  验证码
 * @param telephone 

 * @returns {Promise<unknown>}
 */
const getCode = function (telephone) {

    const opts = {
        url: "/api/common/sendSms",
        params: {
            telephone: telephone,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);

        })
            .catch(err => {
                reject(err);

            })
    });
};

/**
 *  登陆
 * @param platform 账号模型
 * @param captcha 验证码
 * @returns {Promise<unknown>}
 */
const login = function (platform, captcha) {
    document.cookie = null;
    axios.defaults.headers.common["platform_token"] = null;
    const opts = {
        url: "/api/platform/login",
        params: {
            platform: platform,
            captcha: captcha
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);

        })
            .catch(err => {
                reject(err);

            })
    });
};

/**
 * 退出
 */
const logout = function () {
    document.cookie = null;
    axios.defaults.headers.common["platform_token"] = null;

    const opts = {
        url: "/api/platform/logout",
        params: {}
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    })
};
/**
 * -服务者管理列表
 * @param keywords
 * @param status
 * @param pageNo
 * @param pageSize
 * @param city
 * @returns {Promise<unknown>}
 */
const getServiceList = function (keywords, status, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/manage/service/list",
        params: {
            keywords: keywords,
            status: status,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
 * 服务者详情
 * @param serviceUser
 * @returns {Promise<unknown>}
 */
const getServiceDetail = function (serviceUser) {
    const opts = {
        url: "/api/platform/manage/service/list",
        params: {
            serviceUser: serviceUser
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
 * 编辑服务者
 * @param type
 * @param serviceUser
 * @returns {Promise<unknown>}
 */
const editServiceInfo = function (type, serviceUser) {
    const opts = {
        url: "/api/platform/manage/service/edit",
        params: {
            type: type,
            serviceUser: serviceUser,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
 * 获取服务人员岗位列表
 * @returns {Promise<unknown>}
 */
const getPositionList = function () {
    const opts = {
        url: "/api/platform/position/list",
        params: {

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
 * 运营账号列表
 * @param keywords
 * @param status
 * @param pageNo
 * @param pageSize
 * @param city
 * @returns {Promise<unknown>}
 */
const getPuserList = function (keywords, status, pageNo, pageSize, city) {
    const opts = {
        url: "/api/platform/puser/list",
        params: {
            keywords: keywords,
            status: status,
            pageNo: pageNo,
            pageSize: pageSize,
            city: city
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
 *  -运营人员详情
 * @param puser
 * @returns {Promise<unknown>}
 */
const getPuserDetail = function (puser) {
    const opts = {
        url: "/api/platform/puser/info",
        params: {
            puser: puser,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
 * 获取运营人员角色列表
 * @returns {Promise<unknown>}
 */
const getRoleList = function () {
    const opts = {
        url: "/api/platform/role/list",
        params: {

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
 * ,//编辑运营账号
 * @param platform
 * @param type
 * @returns {Promise<unknown>}
 */
const editPuserInfo = function (type, platform) {
    const opts = {
        url: "/api/platform/puser/edit",
        params: {
            type: type,
            platform: platform

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
类目列表
@param keywords
 * @returns {Promise<unknown>}
 */
const getCategoryList = function (keywords) {
    const opts = {
        url: "/api/platform/category/list",
        params: {
            keywords: keywords
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
类目详情
 * @param category
 * @returns {Promise<unknown>}
 */
const getCategoryInfo = function (category) {
    const opts = {
        url: "/api/platform/category/info",
        params: {
            category: category
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
编辑类目
 * @param category
* @param type
 * @returns {Promise<unknown>}
 */
const editCategoryInfo = function (type, category) {
    const opts = {
        url: "/api/platform/category/edit",
        params: {
            type: type,
            category: category
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
类目参数列表
 * @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getArgumentList = function (pageNo, pageSize) {
    const opts = {
        url: "/api/platform/category/argument/list",
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
获取类目参数详情
 * @param argument
 * @returns {Promise<unknown>}
 */
const getArgumentInfo = function (argument) {
    const opts = {
        url: "/api/platform/category/argument/info",
        params: {
            argument: argument
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
类目参数编辑
 * @param type
* @param argument
 * @returns {Promise<unknown>}
 */
const editArgumentInfo = function (type, argument) {
    const opts = {
        url: "/api/platform/category/argument/edit",
        params: {
            type: type,
            argument: argument
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
商品列表
 * @param keywords
* @param status
 * @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getProductList = function (keywords, status, pageNo, pageSize, city) {
    const opts = {
        url: "/api/platform/product/list",
        params: {
            keywords: keywords,
            status: status,
            pageNo: pageNo,
            pageSize: pageSize,
            city
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
编辑商品
 * @param type
* @param product

 * @returns {Promise<unknown>}
 */
const editProductInfo = function (type, product) {
    const opts = {
        url: "/api/platform/product/edit",
        params: {
            type: type,
            product: product,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
获取商品详情
* @param product

 * @returns {Promise<unknown>}
 */
const getProductInfo = function (product) {
    const opts = {
        url: "/api/platform/product/detail",
        params: {
            product: product,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
商品规格组列表
* @param keywords

 * @returns {Promise<unknown>}
 */
const getSkuGroupList = function (keywords) {
    const opts = {
        url: "/api/platform/product/sku/group/list",
        params: {
            keywords: keywords,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
sku组编辑
* @param type
* @param skuGroup
 * @returns {Promise<unknown>}
 */
const editSkuGroupInfo = function (type, skuGroup) {
    const opts = {
        url: "/api/platform/product/sku/group/edit",
        params: {
            type: type,
            skuGroup: skuGroup,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
规格列表
* @param product
 * @returns {Promise<unknown>}
 */
const getProductSkuList = function (product) {
    const opts = {
        url: "/api/platform/sku/list",
        params: {
            product: product,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑商品SKU
* @param type
* @param product
* @param skuList
* @param sku
* @param skuGroupList
 * @returns {Promise<unknown>}
 */
const editProductSku = function (skuList, sku, type, skuGroupList) {
    const opts = {
        url: "/api/platform/product/sku/edit",
        params: {
            skuList: skuList,
            sku: sku,
            type: type,
            skuGroupList: skuGroupList
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-订单列表
* @param order
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
* @param productName
 * @returns {Promise<unknown>}
 */
const getOrderList = function (order, startTime, endTime, pageNo, pageSize, productName) {
    const opts = {
        url: "/api/platform/order/list",
        params: {
            order: order,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
            product: {
                productName
            }
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-订单列表导出
* @param order
* @param startTime
* @param endTime
* @param productName
 * @returns {Promise<unknown>}
 */
const getOrderListExport = function (order, startTime, endTime, productName) {
    const opts = {
        url: "/api/platform/order/list/export",
        params: {
            order: order,
            startTime: startTime,
            endTime: endTime,
            product: {
                productName
            }
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-订单详情
* @param order
 * @returns {Promise<unknown>}
 */
const getOrderDetail = function (order) {
    const opts = {
        url: "/api/platform/order/detail",
        params: {
            order: order,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑预约单\
* @param type
* @param order
 * @returns {Promise<unknown>}
 */
const editOrderInfo = function (type, order) {
    const opts = {
        url: "/api/platform/order/edit",
        params: {
            type: type,
            order: order
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-尾款单退款\

* @param orderChild
 * @returns {Promise<unknown>}
 */
const editOrderchildRefund = function (orderChild) {
    const opts = {
        url: "/api/platform//order/child/cancle",
        params: {
            orderChild: orderChild
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-售后订单列表
* @param orderRefund
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getRefundList = function (orderRefund, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/order/refund/list",
        params: {
            orderRefund: orderRefund,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-售后订单详情
* @param orderRefund
 * @returns {Promise<unknown>}
 */
const getRefundDetail = function (orderRefund) {
    const opts = {
        url: "/api/platform/order/refund/info",
        params: {
            orderRefund: orderRefund,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-售后订单-处理
* @param orderRefund
* @param type
 * @returns {Promise<unknown>}
 */
const editRefundOrderInfo = function (type, orderRefund) {
    const opts = {
        url: "/api/platform/order/refund/edit",
        params: {
            orderRefund: orderRefund,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-评价列表
* @param evaluate
* @param orderSn

* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getEvaluateList = function (evaluate, orderSn, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/evaluate/list",
        params: {
            evaluate: evaluate,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
            order: {
                orderSn: orderSn
            }

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-评价导出
* @param evaluate
* @param startTime
* @param endTime

 * @returns {Promise<unknown>}
 */
const getEvaluateExport = function (evaluate, startTime, endTime) {
    const opts = {
        url: "/api/platform/evaluate/list/export ",
        params: {
            evaluate: evaluate,
            startTime: startTime,
            endTime: endTime,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-评价-编辑
* @param evaluateReply
* @param evaluate
* @param type
 * @returns {Promise<unknown>}
 */
const editEvaluateInfo = function (type, evaluate, evaluateReply) {
    const opts = {
        url: "/api/platform/evaluate/edit",
        params: {
            type: type,
            evaluate: evaluate,
            evaluateReply: evaluateReply,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-获取评论标签列表
* @param evaluateReply
* @param evaluate
* @param type
 * @returns {Promise<unknown>}
 */
const getEvaluateTagList = function () {
    const opts = {
        url: "/api/platform/evaluate/tag/list",
        params: {
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-评论标签编辑
* @param evaluateTag
* @param type
 * @returns {Promise<unknown>}
 */
const editEvaluateTagInfo = function (type, evaluateTag) {
    const opts = {
        url: "/api/platform/evaluate/tag/edit",
        params: {
            type: type,
            evaluateTag: evaluateTag
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-文章列表
* @param keywords
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getArticleList = function (keywords, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/article/list",
        params: {
            keywords: keywords,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-文章详情

* @param article
 * @returns {Promise<unknown>}
 */
const getArticleInfo = function (article) {
    const opts = {
        url: "/api/platform/article/info",
        params: {
            article: article,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑文章

* @param article
* @param type
 * @returns {Promise<unknown>}
 */
const editArticleInfo = function (article, type) {
    const opts = {
        url: "/api/platform/article/edit",
        params: {
            article: article,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-文章标签列表
* @param keywords

 * @returns {Promise<unknown>}
 */
const getTagList = function (keywords,) {
    const opts = {
        url: "/api/platform/tag/list",
        params: {
            keywords: keywords,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-文章标签 新增
* @param keywords
* @param id
* @param type 1新增 2删除

 * @returns {Promise<unknown>}
 */
const editTag = function (keywords, id, type) {
    const opts = {
        url: "/api/platform/tag/edit",
        params: {
            keywords: keywords,
            id,
            type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页广告配置

 * @returns {Promise<unknown>}
 */
const getIndexAdConfig = function () {
    const opts = {
        url: "/api/platform/index/ad/config/info",
        params: {
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**getIndexAdConfig
-首页广告配置编辑

* @param adConfig
 * @returns {Promise<unknown>}
 */
const editIndexAdConfig = function (adConfig) {
    const opts = {
        url: "/api/platform/index/ad/config/edit",
        params: {
            adConfig: adConfig,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页广告列表

* @param ad
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getIndexAdList = function (ad, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/index/ad/list",
        params: {
            ad: ad,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页广告详情

* @param ad
 * @returns {Promise<unknown>}
 */
const getIndexAdInfo = function (ad) {
    const opts = {
        url: "/api/platform/index/ad/info",
        params: {
            ad: ad,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑广告

* @param ad
* @param type
 * @returns {Promise<unknown>}
 */
const editIndexAdInfo = function (ad, type) {
    const opts = {
        url: "/api/platform/index/ad/edit",
        params: {
            ad: ad,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-首页房屋百科列表

* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getIndexArticleList = function (pageNo, pageSize) {
    const opts = {
        url: "/api/platform/index/article/list",
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页房屋百科详情

* @param indexArticle
 * @returns {Promise<unknown>}
 */
const getIndexArticleInfo = function (indexArticle) {
    const opts = {
        url: "/api/platform/index/article/info",
        params: {
            indexArticle: indexArticle,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页房屋百科编辑

* @param indexArticle
* @param type
 * @returns {Promise<unknown>}
 */
const editIndexArticleInfo = function (indexArticle, type) {
    const opts = {
        url: "/api/platform/index/article/edit",
        params: {
            indexArticle: indexArticle,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-商品分类列表

* @param pageNo
* @param pageSize
* @param categoryType //0 全部 1 首页显示
 * @returns {Promise<unknown>}
 */
const getGroupList = function (pageNo, pageSize, categoryType) {
    const opts = {
        url: "/api/platform/index/category/list",
        params: {
            pageNo: pageNo,
            pageSize: pageSize,
            indexCategory: {
                categoryType: categoryType
            }
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页商品分类详情

* @param indexCategory
 * @returns {Promise<unknown>}
 */
const getIndexCategoryInfo = function (indexCategory) {
    const opts = {
        url: "/api/platform/index/category/info",
        params: {
            indexCategory: indexCategory
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑首页商品分类

* @param indexCategory
* @param type
 * @returns {Promise<unknown>}
 */
const editIndexCategoryInfo = function (indexCategory, type) {
    const opts = {
        url: "/api/platform/index/category/edit",
        params: {
            indexCategory: indexCategory,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页商品橱窗列表

* @param indexCategory
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getIndexProductList = function (indexCategory, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/index/product/list",
        params: {
            indexCategory: indexCategory,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页商品橱窗详情

* @param indexProduct
 * @returns {Promise<unknown>}
 */
const getIndexProductInfo = function (indexProduct) {
    const opts = {
        url: "/api/platform/index/product/info",
        params: {
            indexProduct: indexProduct
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-首页商品橱窗编辑

* @param indexProduct
* @param type
 * @returns {Promise<unknown>}
 */
const editIndexProductInfo = function (indexProduct, type) {
    const opts = {
        url: "/api/platform/index/product/edit",
        params: {
            indexProduct: indexProduct,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务策略列表

* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getStrategyList = function (pageNo, pageSize) {
    const opts = {
        url: "/api/platform/strategy/list",
        params: {
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务策略详情

* @param strategry
 * @returns {Promise<unknown>}
 */
const getStrategryInfo = function (strategry) {
    const opts = {
        url: "/api/platform/strategy/info",
        params: {
            strategry: strategry
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑服务策略
* @param indexProduct
* @param type
 * @returns {Promise<unknown>}
 */
const editStrategryInfo = function (strategry, type) {
    const opts = {
        url: "/api/platform/strategy/edit",
        params: {
            strategry: strategry,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务半径列表

* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getCityOpenList = function (pageNo, pageSize) {
    const opts = {
        url: "/api/platform/city/open/list",
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务半径详情

* @param cityOpen
 * @returns {Promise<unknown>}
 */
const getCityOpenInfo = function (cityOpen) {
    const opts = {
        url: "/api/platform/city/open/info",
        params: {
            cityOpen: cityOpen,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑服务半径

* @param cityOpen
* @param type
 * @returns {Promise<unknown>}
 */
const editCityOpenInfo = function (cityOpen, type) {
    const opts = {
        url: "/api/platform/city/open/edit",
        params: {
            cityOpen: cityOpen,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-拼团列表

* @param keywords
* @param status
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getActiveCollectList = function (keywords, status, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/activity/collective/list",
        params: {
            keywords: keywords,
            status: status,
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-拼团详情

* @param collective
 * @returns {Promise<unknown>}
 */
const getActiveCollectInfo = function (collective) {
    const opts = {
        url: "/api/platform/activity/collective/info",
        params: {
            collective: collective,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑拼团

* @param collective
* @param type
 * @returns {Promise<unknown>}
 */
const editActiveCollectInfo = function (collective, type) {
    const opts = {
        url: "/api/platform/activity/collective/edit",
        params: {
            collective: collective,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-获取活动可选商品
* @param keyWord
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getActiveCollectProductList = function (keyWord, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/activity/collective/product/list",
        params: {
            keywords: keyWord,
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务模板列表

* @param serviceTemplate
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getServiceTemplateList = function (serviceTemplate, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/service/template/list",
        params: {
            serviceTemplate: serviceTemplate,
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务模板详情

* @param serviceTemplate
 * @returns {Promise<unknown>}
 */
const getServiceTemplateInfo = function (serviceTemplate) {
    const opts = {
        url: "/api/platform/service/template/info",
        params: {
            serviceTemplate: serviceTemplate
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑服务模板

* @param serviceTemplate
* @param type
 * @returns {Promise<unknown>}
 */
const editServiceTemplateInfo = function (serviceTemplate, type) {
    const opts = {
        url: "/api/platform/service/template/edit",
        params: {
            serviceTemplate: serviceTemplate,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-订单明细列表

* @param order
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getSettlementList = function (order, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/settlement/list",
        params: {
            order: order,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-订单明细列表-导出

* @param order
* @param startTime
* @param endTime
 * @returns {Promise<unknown>}
 */
const exportSettlementList = function (order, startTime, endTime) {
    const opts = {
        url: "/api/platform/sttlement/list/export",
        params: {
            order: order,
            startTime: startTime,
            endTime: endTime
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-售后明细列表

* @param orderRefund
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getAfterSaleList = function (orderRefund, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/order/orderRefund/list",
        params: {
            orderRefund: orderRefund,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-售后明细-导出

* @param orderRefund
* @param startTime
* @param endTime
 * @returns {Promise<unknown>}
 */
const exportAfterSaleList = function (orderRefund, startTime, endTime) {
    const opts = {
        url: "/api/platform/order/orderRefund/list/export",
        params: {
            orderRefund: orderRefund,
            startTime: startTime,
            endTime: endTime
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-发票明细列表

* @param invoice
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
* @param productName
 * @returns {Promise<unknown>}
 */
const getInvoiceList = function (invoice, startTime, endTime, pageNo, pageSize, productName) {
    const opts = {
        url: "/api/platform/invoice/list",
        params: {
            invoice: invoice,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
            product: {
                productName
            }
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-发票明细列表-导出

* @param order
* @param startTime
* @param endTime
* @param productName
 * @returns {Promise<unknown>}
 */
const exportInvoiceList = function (invoice, startTime, endTime, productName) {
    const opts = {
        url: "/api/platform/invoice/list/export",
        params: {
            invoice: invoice,
            startTime: startTime,
            endTime: endTime,
            product: {
                productName
            }
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-结算管理-编辑发票

* @param invoice
* @param type
 * @returns {Promise<unknown>}
 */
const editInvoiceInfo = function (invoice, type) {
    const opts = {
        url: "/api/platform/invoice/edit",
        params: {
            invoice: invoice,
            type: type,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务单列表

* @param orderReservation
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getReservationList = function (orderReservation, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/order/reservation/list",
        params: {
            orderReservation: orderReservation,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
--服务单详情

* @param orderReservation

 * @returns {Promise<unknown>}
 */
const getReservationInfo = function (orderReservation) {
    const opts = {
        url: "/api/platform/order/reservation/info",
        params: {
            orderReservation: orderReservation,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑服务单

* @param orderReservation
* @param type

 * @returns {Promise<unknown>}
 */
const editReservationInfo = function (orderReservation, type) {
    const opts = {
        url: "/api/platform/order/reservation/edit",
        params: {
            orderReservation: orderReservation,
            type: type,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-获取服务单可派人员

* @param startTime
* @param endTime

 * @returns {Promise<unknown>}
 */
const getReservationUserList = function (startTime, endTime) {
    const opts = {
        url: "/api/platform/order/reservation/suser/list",
        params: {
            startTime: startTime,
            endTime: endTime,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务端改派单列表

* @param orderReservationResend
* @param startTime
* @param endTime
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getServiceResendList = function (orderReservationResend, startTime, endTime, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/order/reservation/resend/list",
        params: {
            orderReservationResend: orderReservationResend,
            startTime: startTime,
            endTime: endTime,
            pageNo: pageNo,
            pageSize: pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-服务端改派单详情

* @param orderReservationResend
* @param startTime
* @param endTime
 * @returns {Promise<unknown>}
 */
const getServiceResendInfo = function (orderReservationResend, startTime, endTime) {
    const opts = {
        url: "/api/platform/order/reservation/resend/info",
        params: {
            orderReservationResend: orderReservationResend,
            startTime: startTime,
            endTime: endTime,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑服务单改派

* @param orderReservationResend
* @param type
 * @returns {Promise<unknown>}
 */
const editServiceResendInfo = function (orderReservationResend, type) {
    const opts = {
        url: "/api/platform/order/reservation/resend/edit",
        params: {
            orderReservationResend: orderReservationResend,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-问题列表

* @param productQuestion
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getQuestionList = function (productQuestion, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/product/question/list",
        params: {
            productQuestion: productQuestion,
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-商品问题-详情-

* @param productQuestion
 * @returns {Promise<unknown>}
 */
const getQuestionInfo = function (productQuestion) {
    const opts = {
        url: "/api/platform/product/question/info",
        params: {
            productQuestion: productQuestion,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-商品问题编辑

* @param productQuestion
* @param type
 * @returns {Promise<unknown>}
 */
const editQuestionInfo = function (productQuestion, type) {
    const opts = {
        url: "/api/platform/product/question/edit",
        params: {
            productQuestion: productQuestion,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-商品问题答案编辑

* @param productQuestionAnswer
* @param type
 * @returns {Promise<unknown>}
 */
const editAnswerInfo = function (productQuestionAnswer, type) {
    const opts = {
        url: "/api/platform/product/question/answer/edit",
        params: {
            productQuestionAnswer: productQuestionAnswer,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-消息列表

* @param message
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getMessageList = function (message, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/message/list",
        params: {
            message: message,
            pageNo: pageNo,
            pageSize: pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-消息-编辑

* @param message
* @param type
 * @returns {Promise<unknown>}
 */
const editMessageInfo = function (message, type) {
    const opts = {
        url: "/api/platform/message/edit",
        params: {
            message: message,
            type: type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-内容管理-商品分类列表

* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getProductCategoryList = function (pageNo, pageSize) {
    const opts = {
        url: "/api/platform/product/category/list",
        params: {
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-内容管理-商品分类编辑

* @param productCategory
* @param type
 * @returns {Promise<unknown>}
 */
const editProductCategoryInfo = function (productCategory, type) {
    const opts = {
        url: "/api/platform/product/category/edit",
        params: {
            productCategory,
            type,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-商品分类-橱窗商品列表

* @param productCategory
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getCategoryProductList = function (productCategory, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/category/product/list",
        params: {
            productCategory,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-商品分类-橱窗商品编辑

* @param categoryProduct
* @param type
 * @returns {Promise<unknown>}
 */
const editCategoryProductInfo = function (categoryProduct, type) {
    const opts = {
        url: "/api/platform/category/product/edit",
        params: {
            categoryProduct,
            type,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-优惠券-列表

* @param keywords
* @param status  1 未开始 2 进行中 3 已结束
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getCouponList = function (keywords, status, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/coupon/list",
        params: {
            keywords,
            status,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-详情

* @param id

 * @returns {Promise<unknown>}
 */
const getCouponInfo = function (id) {
    const opts = {
        url: "/api/platform/coupon/info",
        params: {
            id,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-编辑

* @param type
* @param coupon

 * @returns {Promise<unknown>}
 */
const editCouponInfo = function (type, coupon) {
    const opts = {
        url: "/api/platform/coupon/edit",
        params: {
            type,
            coupon,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-编辑商品列表

* @param id

 * @returns {Promise<unknown>}
 */
const getCouponProductList = function (id) {
    const opts = {
        url: "/api/platform/coupon/product/list",
        params: {
            id,

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-编辑商品

* @param type
* @param couponProduct

 * @returns {Promise<unknown>}
 */
const editCouponProductInfo = function (type, couponProduct) {
    const opts = {
        url: "/api/platform/coupon/product/edit",
        params: {
            type,
            couponProduct,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-自动发券
 * @returns {Promise<unknown>}
 */
const getAutoSetCouponList = function () {
    const opts = {
        url: "/api/platform/user/coupon/autoSet/list",
        params: {

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-自动发券编辑
* @param ids
 * @returns {Promise<unknown>}
 */
const editAutoSetCoupon = function (ids) {
    const opts = {
        url: "/api/platform/user/coupon/autoSet/edit",
        params: {
            ids
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-手动发券-获取用户信息
* @param keywords
 * @returns {Promise<unknown>}
 */
const getUserInfo = function (keywords) {
    const opts = {
        url: "/api/platform/user/info",
        params: {
            keywords
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-手动发券-添加新用户
* @param keywords
 * @returns {Promise<unknown>}
 */
const addUserInfo = function (keywords) {
    const opts = {
        url: "/api/platform/user/add",
        params: {
            keywords
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-手动发券-获取用户信息
* @param uUserTelephone
* @param type
 * @returns {Promise<unknown>}
 */
const editUserInfo = function (uUserTelephone, type) {
    const opts = {
        url: "/api/platform/user/edit",
        params: {
            user: {
                uUserTelephone,
            },
            type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-手动发券-列表

* @param telephone
* @param couponName  
* @param couponCode
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getUserCouponList = function (telephone, couponName, couponCode, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/user/coupon/list",
        params: {
            couponUser: {
                telephone,
                couponCode,
                couponName,
            },
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-手动发券-编辑

* @param type 1 发券 2 失效
* @param couponUserList
* @param id
* @param telephone
* @param captcha

 * @returns {Promise<unknown>}
 */
const editUserCouponInfo = function (type, couponUserList, id, telephone, captcha) {
    const opts = {
        url: "/api/platform/user/coupon/edit",
        params: {
            type,
            couponUserList,
            id,
            telephone,
            captcha,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-优惠券-获取小程序码
* @param wx


 * @returns {Promise<unknown>}
 */
const getWxacode = function (wx) {
    const opts = {
        url: "/api/common/wx/wxacode",
        params: {
            wx,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-用户列表

* @param user

* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getClientList = function (user, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/user/list",
        params: {
            user,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-用户详情

* @param id
* @param type
* @param pageNo
* @param pageSize
 * @returns {Promise<unknown>}
 */
const getClientInfo = function (id, type, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/user/detail",
        params: {
            id,
            type,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-预览验房报告


* @param orderReservationSn

 * @returns {Promise<unknown>}
 */
const getCheckReportPdf = function (orderReservationSn) {
    const opts = {
        url: "/api/user/checkReport/pdf",
        params: {

            orderReservation: {
                orderReservationSn,
            },

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-替客户预约
* @param id
* @param orderReservation

 * @returns {Promise<unknown>}
 */
const addOrderReservation = function (id, orderReservation) {
    const opts = {
        url: "/api/platform//order/reservation/add/platform/v3",
        //  url: "/api/platform/user/order/reservation/add/v3",
        // url:"/api/user/order/reservation/add/platform/v3",  
        params: {
            id,
            orderReservation
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-用户地址列表
* @param  id
* @param pageNo
* @param pageSize

 * @returns {Promise<unknown>}
 */
const getUserAddressList = function (id) {
    const opts = {
        url: "/api/platform/address/list",
        params: {
            id,
            pageNo: 1,
            pageSize: 100
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
///////////////////3期//////////////////////////
/**
-好友助力活动列表
* @param  keywords
* @param  status  1 未开始 2 进行中 3 已失效
* @param pageNo
* @param pageSize

 * @returns {Promise<unknown>}
 */
const getAssistacneActivityList = function (keywords, status, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/asActivity/list",
        params: {
            keywords,
            status,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-好友助力活动详情
* @param  id
 * @returns {Promise<unknown>}
 */
const getAssistacneActivityInfo = function (id) {
    const opts = {
        url: "/api/platform/asActivity/info",
        params: {
            id,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-编辑好友助力活动
* @param  asActivity
* @param  type 1 新增 2 编辑 3 删除 4 失效
 * @returns {Promise<unknown>}
 */
const editAssistacneActivityInfo = function (asActivity, type) {
    const opts = {
        url: "/api/platform/asActivity/edit",
        params: {
            asActivity,
            type
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-全民营销规则列表

* @param pageNo
* @param pageSize

 * @returns {Promise<unknown>}
 */
const getPromotionRuleList = function (pageNo, pageSize, status) {
    const opts = {
        url: "/api/platform/promotion/rule/list",
        params: {
            pageNo,
            pageSize,
            status
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-全民营销规则历史列表

* @param id
* @param pageNo
* @param pageSize

 * @returns {Promise<unknown>}
 */
const getPromotionRuleHistoryList = function (id, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/promotion/rule/history/list",
        params: {
            id,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-编辑全民营销规则
* @param  promotionRule
* @param  type 1 新增 2 编辑 3 删除 4 生效5 失效 6 全部生效 7 全部生效
* @param  telephone
* @param  captcha

 * @returns {Promise<unknown>}
 */
const editPromotionRuleInfo = function (promotionRule, type, telephone, captcha,) {
    const opts = {
        url: "/api/platform/promotion/rule/edit",
        params: {
            promotionRule,
            type,
            telephone,
            captcha,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-全民营销规则有效期

 * @returns {Promise<unknown>}
 */
const getPromotionRuleTime = function () {
    const opts = {
        url: "/api/platform/promotion/rule/time",
        params: {

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
-全民营销规则有效期历史

* @param id
* @param pageNo
* @param pageSize

 * @returns {Promise<unknown>}
 */
const getPromotionRuleTimeHistoryList = function (id, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/promotion/rule/time/history/list",
        params: {
            id,
            pageNo,
            pageSize,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
-编辑全民营销规则有效期
* @param  id
* @param  limitTime 
* @param  telephone
* @param  captcha
 * @returns {Promise<unknown>}
 */
const editPromotionRuleTimeInfo = function (id, limitTime, telephone, captcha) {
    const opts = {
        url: "/api/platform/promotion/rule/time/edit",
        params: {
            id,
            limitTime,
            telephone,
            captcha
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
- 编辑用户推广人信息
* @param  uUserId
* @param  telephone

 * @returns {Promise<unknown>}
 */
const editUserPromotionInfo = function (uUserId, telephone) {
    const opts = {
        url: "/api/platform/user/promotion/edit",
        params: {
            user: {
                uUserId,
            },

            telephone,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
- 获取菜单列表
* @param  id

 * @returns {Promise<unknown>}
 */
const getMenuList = function (id) {
    const opts = {
        url: "/api/platform/all/menu/list",
        params: {
            id,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
- 获取没有角色的运营账户列表
* @param  id

 * @returns {Promise<unknown>}
 */
const getnoBindRoleList = function (id) {
    const opts = {
        url: "/api/platform/role/nbind/user/list",
        params: {
            id,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
- 获取角色的列表
* @param  keywords
* @param  pageNo
* @param  pageSize

 * @returns {Promise<unknown>}
 */
const getRoleMenuList = function (keywords, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/v2/role/list",
        params: {
            keywords,
            pageNo,
            pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
- 编辑角色
* @param  type
* @param  roleId
* @param  roleName
* @param  menuList
* @param  puserList

 * @returns {Promise<unknown>}
 */
const editRoleInfo = function (type, roleId, roleName, menuList, puserList) {
    const opts = {
        url: "/api/platform/role/edit",
        params: {
            type,
            role: {
                roleId,
                roleName,
            },
            menuList,
            puserList
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
- 编辑角色
* @param  id


 * @returns {Promise<unknown>}
 */
const getRoleMenuInfo = function (id) {
    const opts = {
        url: "/api/platform/v2/role/info",
        params: {
            id,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
- 获取后台用户菜单/按钮权限


 * @returns {Promise<unknown>}
 */
const getSystemRoleInfo = function () {
    const opts = {
        url: "api/platform//system/menu/list",
        params: {

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
- 获取后台用户菜单/按钮权限


 * @returns {Promise<unknown>}
 */
const getAddressConfigList = function (city) {
    const opts = {
        url: "/api/platform/address/config/list",
        params: {
            city,

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
- 全民规则营销列表同步类目列表2级类目


 * @returns {Promise<unknown>}
 */
const getPromotionRuleCategory = function (city) {
    const opts = {
        url: "/api/platform/promotion/rule/category/sync",
        params: {
            city,

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//后台申请退款

const cancelOrderApply = function (type, order) {
    const opts = {
        url: "/api/platform/order/cancle/audit",
        params: {
            type,
            order

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//佣金账户列表

const getCommisionList = function (telephone, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/promotion/commission/user/list ",
        params: {
            telephone,
            pageNo,
            pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//佣金订单列表

const getCommisionOrderList = function (uUserId, orderSn, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/promotion/commission/user/order/list",
        params: {
            order: {
                uUserId,
                orderSn,
            },
            pageNo,
            pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//佣金明细列表

const getCommisionDetailList = function (uUserId, type, pageNo, pageSize) {
    const opts = {
        url: "/api/platform/promotion/commission/user/progress/list",
        params: {
            order: {
                uUserId
            },
            type,
            pageNo,
            pageSize
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//红包审核
const editCommissionAudit = function (id, type,auditRemark) {
    const opts = {
        url: "/api/platform/promotion/commission/user/progress/audit",
        params: {
            id,
            type,
            auditRemark,
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
* 调起微信登陆 获取code
* @returns {Promise<unknown>}
*/
const toWxLogin = function () {
    const opts = {
        url: "/api/user/mp/login/wxCode",
        params: {

        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    })
};
/**
* 发红包
* @returns {Promise<unknown>}
*/
const getRedPack = function () {
    const opts = {
        url: "/api/user/commission/withdraw",
        params: {
            money: money
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    })
};
/**
*  通过code获取openid
 *  @param code 
*/
const wxLogin = function (code) {
    const opts = {
        url: "/api/user/mp/login/wx",
        params: {
            code
        }
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    })
};
export default function (Vue) {
    //添加全局API
    Vue.prototype.$api = {
        getCode,//获取验证码
        login,//登录
        logout,//退出
        getServiceList, //服务者管理列表
        getServiceDetail, //服务者详情
        editServiceInfo, //编辑服务者
        getPositionList, //获取服务人员岗位列表
        getPuserList, //运营账号列表
        getPuserDetail, //运营人员详情    
        editPuserInfo,//编辑运营账号
        getRoleList,//获取运营人员角色列表
        getCategoryList,//类目列表
        getCategoryInfo,//类目详情
        editCategoryInfo,//编辑类目
        getArgumentList,//类目参数列表
        getArgumentInfo,//获取类目参数详情
        editArgumentInfo,//类目参数编辑
        getProductList,//商品列表
        editProductInfo,//编辑商品
        getProductInfo,//获取商品详情
        getSkuGroupList,//商品规格组列表
        editSkuGroupInfo,//sku组编辑
        getProductSkuList,//规格列表
        editProductSku,//编辑商品SKU
        getOrderList,//订单列表
        getOrderListExport,//订单列表导出
        getOrderDetail,//订单详情
        editOrderInfo,//编辑订单
        getRefundList,//售后订单列表
        getRefundDetail,//售后订单详情
        editRefundOrderInfo,///售后订单-处理
        getEvaluateList,//评价列表
        getEvaluateExport,//评价导出
        editEvaluateInfo,//评价-编辑
        getEvaluateTagList,//评价标签列表
        editEvaluateTagInfo,//评价标签编辑
        getArticleList,//文章列表
        getArticleInfo,//文章详情
        editArticleInfo,//编辑文章
        getIndexAdConfig,//首页广告配置
        editIndexAdConfig,//编辑首页广告配置
        getIndexAdList,//首页广告列表
        getIndexAdInfo,//首页广告详情
        editIndexAdInfo,//编辑广告
        getIndexArticleList,//首页房屋百科列表
        getIndexArticleInfo,//首页房屋百科详情
        editIndexArticleInfo,//首页房屋百科编辑
        getGroupList,//商品分类列表
        getIndexCategoryInfo,//首页商品分类详情
        editIndexCategoryInfo,//编辑首页商品分类
        getIndexProductList,//首页商品橱窗列表
        getIndexProductInfo,//首页商品橱窗详情
        editIndexProductInfo,//首页商品橱窗编辑
        getStrategyList,//服务策略列表
        getStrategryInfo,//服务策略详情
        editStrategryInfo,//编辑服务策略
        getCityOpenList,//服务半径列表
        getCityOpenInfo,//服务半径详情
        editCityOpenInfo,//编辑服务半径
        getActiveCollectList,//拼团列表
        getActiveCollectInfo,//拼团详情
        editActiveCollectInfo,//编辑拼团
        getActiveCollectProductList,//获取活动可选商品
        getServiceTemplateList,//服务模板列表
        getServiceTemplateInfo,//服务模板详情
        editServiceTemplateInfo,//编辑服务模板
        getSettlementList,//结算管理-订单明细列表
        exportSettlementList,//结算管理-订单明细列表-导出
        getAfterSaleList,//结算管理-售后明细列表
        exportAfterSaleList,//结算管理-售后明细-导出
        getInvoiceList,//结算管理-发票明细列表
        exportInvoiceList,//结算管理-发票明细列表-导出
        editInvoiceInfo,//-编辑发票
        getReservationList,//服务单列表
        getReservationInfo,//服务单详情
        editReservationInfo,//编辑服务单
        getReservationUserList,//获取服务单可派人员
        getServiceResendList,//服务端改派单列表
        getServiceResendInfo,//服务端改派单详情
        editServiceResendInfo,//编辑服务单改派
        getQuestionList,//问题列表
        getQuestionInfo,//-商品问题-详情
        editQuestionInfo,//商品问题编辑
        editAnswerInfo,//商品问题答案编辑
        getMessageList,//消息列表
        editMessageInfo,//编辑消息
        // getinfo,
        getProductCategoryList,//内容管理-商品分类列表
        editProductCategoryInfo,//内容管理-商品分类编辑
        getCategoryProductList,//商品分类-橱窗商品列表
        editCategoryProductInfo,//商品分类-橱窗商品编辑

        getCouponList,
        getCouponInfo,
        editCouponInfo,
        editCouponProductInfo,
        getUserCouponList,
        getCouponProductList,
        editUserCouponInfo,
        getAutoSetCouponList,
        editAutoSetCoupon,
        getUserInfo,
        addUserInfo,//2022.04.25用户不存在，添加新用户
        editUserInfo,
        getWxacode,
        getTagList,
        editTag,

        getClientList,
        getClientInfo,
        getCheckReportPdf,

        addOrderReservation,
        getUserAddressList,

        ////////////////////////////
        getMenuList,//获取菜单列表
        getSystemRoleInfo,//获取后台用户菜单/按钮权限
        getRoleMenuList, //获取角色的列表
        getRoleMenuInfo, //获取角色的详情
        getnoBindRoleList,//获取没有角色的运营账户列表
        editRoleInfo,//编辑角色
        getAssistacneActivityList,//好友助力活动列表
        getAssistacneActivityInfo,//好友助力活动列表
        editAssistacneActivityInfo,//编辑好友助力活动
        getPromotionRuleList,//全民营销规则列表
        getPromotionRuleHistoryList,//全民营销规则历史列表
        editPromotionRuleInfo,//编辑全民营销规则
        getPromotionRuleTime,//全民营销规则有效期
        getPromotionRuleTimeHistoryList,//全民营销规则有效期历史
        editPromotionRuleTimeInfo,//编辑全民营销规则有效期,
        editUserPromotionInfo,//用户推广人信息接口

        getAddressConfigList,//获取地址配置列表
        editOrderchildRefund,//尾款单退款

        getPromotionRuleCategory,//
        cancelOrderApply,//取消后台订单
        getCommisionList,
        getCommisionOrderList,
        getCommisionDetailList,
        editCommissionAudit,//红包发放审核

        wxLogin,//通过code拿到openid
        toWxLogin,//调起微信登陆 获取code
        getRedPack,//发红包

    }
}