<template>
    <div id="app">

        <router-view v-if="isAlive"></router-view>

    </div>
</template>
<script>

export default {
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isAlive: true,


        }
    },

    mounted() {
        console.log(window.location.href)
        let herf=window.location.href
        if (!herf.includes("officalAccount")){
            this.getMessageList()
        }
    },
    methods: {


        reload() {
            this.isAlive = false;
            this.$nextTick(function () {
                this.isAlive = true
            })
        },
        getMessageList() {
            let message = {
                messageType: 1
            }
            this.$api.getMessageList(message, 1, 1).then((res) => {
                if (res.resultCode == 10000) {
                    if (res.result.message) {
                        let info = res.result.message
                        let messageNum = info.dealCount + info.activityCount
                        this.$store.commit("setmessageInfo", messageNum);
                    }
                } else {
                    this.$store.commit("setmessageInfo", 0);
                }
            })

        },



    }
}
</script>

<style lang="scss">
#app {
    height: 100%;
    background: #f8f8f8;
}

#posterHtml {
    // width: 27rem;
    // height: 58rem;
    // width: 375px;
    // height: 812px;
    width: 750px;
    height: 1624px;
    background: url('.../../assets/images/poster_bg.png') 100% 100%;
    background-size: 100% 100%;
    position: relative;

    .posterContainer {
        position: absolute;
        // width: 23rem;
        // height: 31rem;
        // width: 325px;
        // height: 434px;
        width: 650px;
        height: 868px;
        background: url('.../../assets/images/poster_coupon_bg.png') 100% 100%;
        background-size: 100% 100%;
        //  top: 18rem;
        top: 496px;
        left: 50%;
        transform: translateX(-50%);

        .couponPrice {
            // padding-top: 2rem;
            padding-top: 130px;
            text-align: center;
            color: #e11f1f;

            .coupon_price {
                // font-size: 3.5rem;
                font-size: 100px;
                font-weight: bold;
                transform: translateY(20px);
            }

            .coupon_price_str {
                // font-size: 1.5rem;
                font-size: 40px;
            }
        }

        .coupon_limited {
            text-align: center;
            color: #666666;
            // line-height: 2rem;
            //  font-size: 1rem;
            font-size: 28px;
            line-height: 70px;
        }

        .coupon_date {
            text-align: center;
            color: #666666;
            // line-height: 2rem;
            // font-size: 0.9rem;
            //padding: 2rem 0;
            font-size: 22px;
            padding: 40px 0;
        }

        .coupon_code {
            // width: 10rem;
            width: 280px;
            margin: 0 auto;

            img {
                width: 280px;
                //       width: 10rem;
            }
        }

        .coupon_tip {
            text-align: center;
            color: #666666;
            font-size: 24px;

            line-height: 56px;
            //font-size: 0.9rem;
            //padding: 0.5rem 0;
        }
    }
}

.posterHtmlModal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;

    .posterHtmlView {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 20rpx;
        padding: 2rem 2rem 3rem;
        width: 25rem;
        text-align: center;
        border-radius: 10px;

        .posterHtmlContent {
            width: 100%;
            position: relative;

            .closeView {
                position: absolute;
                width: 3rem;
                top: -2rem;
                right: -6rem;

                img {
                    width: 2.5rem;
                }
            }
        }

        img {
            width: 100%;
            margin: 0 auto;
        }

        .posterBtn {
            margin-top: 2rem;

            div {
                width: 50%;
                text-align: center;
                font-size: 0.9rem;
                color: #39b7a5;
            }
        }
    }
}</style>
