<template>
    <div class="dispatchModal" v-if="dispatchModal">
        <div class="dispatchView flex_g flex_column">
            <div class="Modal_caption flex_g vertical between">
                <div>人工派单</div>
                <img @click="closeDispatchModal" src="@/assets/images/close_icon.png" alt="">
            </div>
            <div class="sUserName" v-if="sUserName!=''">当前服务人员：{{sUserName}}，请选择改派给谁</div>
            <div class="timePicker flex_g vertical flex_center flex_wrap">
                <p>预约时间:</p>
                <!-- <el-date-picker :clearable="false" v-model="reserveTime" type="datetimerange" :picker-options="pickerOptionsTime" @change="getTime" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker> -->
                <div style="margin-right:1rem">
                    <el-date-picker v-model="dateTime" :clearable="false" style="width:13rem" type="date" placeholder="选择日期" :picker-options="pickerOptionsTime">
                    </el-date-picker>
                </div>
                <div style="margin-right:1rem">
                    <el-time-select placeholder="起始时间" :clearable="false" style="width:9rem" v-model="startTime" :picker-options="{start: '08:00', step: '00:60',end: '18:00'}">
                    </el-time-select>
                </div>
                <div style="margin-right:1rem">
                    <el-time-select placeholder="结束时间" :clearable="false" style="width:9rem" v-model="endTime" :picker-options="{start: '08:00', step: '00:60',end: '18:00',minTime: startTime}">
                    </el-time-select>
                </div>
                <div class="search_btn" @click="searchTime">搜索</div>

            </div>
            <div class="serviceList">
                <div class="serviceItem " v-for="(item,index) of serviceList" :key="index" @click="changeService(item.sUserId)">
                    <div class="serviceItemInfo flex_g vertical">
                        <div class="radius">
                            <img v-if="serviceId!=item.sUserId" src="@/assets/images/radius_default.png" alt="">
                            <img v-if="serviceId==item.sUserId" src="@/assets/images/radius_active.png" alt="">
                        </div>
                        <div class="serviceInfo flex_g vertical">
                            <div class="userImg">
                                <img v-if="item.sUserImg" :src="item.sUserImg" alt="">
                                <img v-if="!item.sUserImg" src="@/assets/images/default_user.png" alt="">
                            </div>
                            <div class="userInfo">
                                <div class="flex_g vertical">
                                    <span>{{item.sUserRealName}}</span>
                                    <span>{{item.sTelephone}}</span>
                                    <div class="starImg flex_g vertical" v-if="item.score>0">
                                        <img src="@/assets/images/star_active.png" v-for="(s_item,s_index) of item.star" alt="" :key="s_index">
                                        <img src="@/assets/images/star_default.png" v-for="(d_item,d_index) of item.defaultstar" alt="" :key="d_index*item.star">
                                        <p>{{item.scoreStr}}分</p>
                                    </div>
                                </div>
                                <div class="address" v-if="item.addressInfo">常驻地址：{{item.addressInfo}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="serviceOrder" v-if="item.orderCount>0">当前已接({{item.orderCount}})单</div>
                </div>

            </div>
            <div class="btnView flex_g vertical flex_endX">
                <p @click="closeDispatchModal">取消</p>
                <p @click="configDispatch">确定</p>
            </div>

        </div>
    </div>
    <!-- 父组件使用 -->
    <!-- <div is='errorTip' ref="errorTip"></div> -->
</template>

<script>
export default {
    name: "errorTip",
    data() {
        return {
            serviceList: [],
            dispatchModal: false, //是否展示
            dateTime: '',
            startTime: '',
            endTime: '',
            serviceId: -1,
            editRid: '',
            sUserName: '',
            pickerOptionsTime: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的 
                }
            },
        };
    },

    methods: {
        readyDispatchModal(item, id, rid) {
            console.log(item)
            this.dispatchModal = true

            this.startTime = new Date(item.visitStartTime.replace(/-/g,'/')).getHours() + ':00'
            this.endTime = new Date(item.visitEndTime.replace(/-/g,'/')).getHours() + ':00'
            this.dateTime = new Date(item.visitEndTime.replace(/-/g,'/')).getTime()
            let startTime = new Date(item.visitStartTime.replace(/-/g,'/')).getTime()
            let endTime = new Date(item.visitEndTime.replace(/-/g,'/')).getTime()
            console.log(this.startTime)
            if (item.sUserRealName && item.sUserRealName != '') {
                this.sUserName = item.sUserRealName
            }
            if (rid && rid != '') {
                this.editRid = rid
            }

            this.editId = id
            this.serviceId = -1
          //  alert('开始时间'+startTime+'结束时间'+endTime)
           this.getReservationUserList(startTime, endTime)
        },
        //获取服务单可派人员
        getReservationUserList(startTime, endTime) {
            //   startTime=  1621659600000
            //   endTime=  1621659600000
            this.$api.getReservationUserList(startTime, endTime).then((res) => {
                if (res.resultCode == 10000) {
                    if (res.result) {
                        let list = res.result
                        if (list.length > 0) {
                            for (let i = 0; i < list.length; i++) {
                                if (list[i].suserAddress) {
                                    let address = list[i].suserAddress
                                    list[i].addressInfo = address.province + address.city + address.county + address.complateAddress + address.houseNum
                                }
                                if (list[i].score != 0) {
                                    if (list[i].score > 100) {
                                        list[i].score = 100
                                    }
                                    list[i].scoreStr = (list[i].score / 20).toFixed(1)
                                    list[i].star = Math.round(list[i].score / 20)
                                    list[i].defaultstar = Number(5 - Math.round(list[i].score / 20))
                                }
                                // if (list[i].scoreStr != 0) {
                                //     list[i].scoreStr = (list[i].scoreStr / 20).toFixed(1)
                                // }
                            }
                            this.serviceId = list[0].sUserId
                        }
                        this.serviceList = list
                    }
                }
            })
        },
        //确认服务师
        changeService(id) {
            this.serviceId = id
        },
        closeDispatchModal() {
            this.dispatchModal = false
        },

        searchTime() {
            let date = this.$getDate(this.dateTime).slice(0, 11)
            let startTime = new Date(date + ' ' + this.startTime).getTime()
            let endTime = new Date(date + ' ' + this.endTime).getTime()
            this.getReservationUserList(startTime, endTime)
        },
        configDispatch() {
            this.closeDispatchModal()
            let date = this.$getDate(this.dateTime).slice(0, 11)
            date=date.replace('-','/').replace('-','/')
            console.log('日期截取--' + date)
            let startTime = new Date(date + ' ' + this.startTime).getTime()
            let endTime = new Date(date + ' ' + this.endTime).getTime()
            console.log('开始日期--' + startTime)
            console.log('结束日期--' + endTime)      
            const data = {
                id: this.editId,
                sId: this.serviceId,
                rid: this.editRid,
                startTime: startTime,
                endTime: endTime
            }
             this.$emit('configDispatch', data)
        },
    }
};
</script>

<style lang="scss" >
.dispatchModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 100;
    .dispatchView {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50rem;
        height: 55rem;
        border-radius: 10px;
        background: #fff;
        padding: 2rem 3rem;
        .Modal_caption {
            div {
                color: #333;
                font-size: 1.2rem;
                font-weight: bold;
            }
            img {
                width: 1rem;
            }
        }
        .sUserName {
            text-align: center;
            padding-top: 1rem;
            color: #999;
            font-size: 0.9rem;
        }
        .timePicker {
            margin: 1.5rem 0;
            p {
                padding-right: 0.5rem;
                flex-shrink: 0;
            }
            .el-date-table td.current:not(.disabled) span {
                background-color: #39b7a5 !important;
            }
            .el-date-editor .el-input__inner{
                height: 2.4rem;
                line-height: 2.4rem;
            }
            .el-date-editor .el-range__icon {
                line-height: 1.9rem !important;
            }
            .el-date-editor .el-range__close-icon {
                line-height: 1.9rem !important;
            }
            .el-date-editor .el-range-separator {
                line-height: 1.9rem !important;
            }
            .search_btn {
                color: #fff;
                padding: 0 1rem;
                border-radius: 5px;
                background: #39b7a5;
                font-size: 0.8rem;
                height: 2.2rem;
                line-height: 2.2rem;
                cursor: pointer;
            }
        }
        .serviceList {
            flex: 1;
            overflow-y: auto;
            .serviceItem {
                padding: 1.5rem 0;
                border-bottom: 1px solid #eee;
                .radius {
                    margin-right: 1.5rem;
                    img {
                        width: 1rem;
                    }
                }
                .serviceInfo {
                    flex: 1;
                    overflow: hidden;
                    .userImg {
                        flex-shrink: 0;
                        width: 3rem;
                        height: 3rem;
                        margin-right: 1rem;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                    .userInfo {
                        flex: 1;
                        overflow: hidden;
                        span:nth-child(1) {
                            color: #333;
                            font-weight: bold;
                            font-size: 0.9rem;
                            padding-right: 0.5rem;
                        }
                        span:nth-child(2) {
                            color: #666;
                            font-size: 0.9rem;
                            padding-right: 0.5rem;
                        }
                        .starImg {
                            margin-left: 0.8rem;
                            img {
                                width: 0.9rem;
                                margin: 0 0.1rem;
                            }
                            p {
                                color: #666;
                                font-size: 0.8rem;
                                padding-left: 0.5rem;
                            }
                        }
                        .address {
                            color: #666;
                            font-size: 1rem;
                            line-height: 1.5rem;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .serviceOrder {
                    display: inline-block;
                    margin-left: 2.5rem;
                    margin-top: 1rem;
                    background: rgba(57, 183, 165, 0.1);
                    border-radius: 5px;
                    padding: 0.5rem 1.5rem;
                    color: #39b7a5;
                    font-weight: bold;
                    font-size: 0.9rem;
                    position: relative;
                }
                .serviceOrder:after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 1rem;
                    width: 0;
                    height: 0;
                    border-bottom: 10px solid rgba(57, 183, 165, 0.1);
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                }
            }
        }
        .btnView p {
            width: 6rem;
            padding: 0.5rem 0;
            border-radius: 5px;
            font-size: 1rem;
            margin: 0 1rem;
            text-align: center;
            cursor: pointer;
        }

        .btnView p:nth-child(1) {
            color: #999;
            border: 1px solid #e5e5e5;
        }

        .btnView p:nth-child(2) {
            color: #fff;
            background-color: #39b7a5;
            border: 1px solid #39b7a5;
        }
    }
    .el-date-table td .today div span {
        background-color: #39b7a5 !important;
    }
    .el-date-table td.current:not(.disabled) div span {
        background-color: #39b7a5 !important;
    }
    .el-date-table td span {
        background-color: #39b7a5 !important;
    }
}
</style>