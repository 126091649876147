function DivScrooll(scrollDiv, head) {
    scrollDiv.addEventListener("scroll", function() {
        if (scrollDiv.scrollTop > 10) {
            head.style.boxShadow = " 0px 0px 19px 2px rgba(0, 0, 0, 0.1)";
        } else {
            head.style.boxShadow = "";
        }
    });
}

export default DivScrooll;