<!--分页-->
<template>
    <div class="pagination flex_g vertical flex_endX" v-show="total != 0">
        <div class="flex_g between vertical" style="width:100%">
            <div class="total_num">
                总条数：{{total_num}} 总页数：{{pageNo}}/{{pageCount}}
            </div>
            <div class="page flex_g flex_endX vertical">
                <el-pagination background @size-change="handleSizeChange" @current-change="pageNoChange" :current-page.sync="pageNo" layout="sizes,prev, pager, next,jumper " :total="total" :page-size="pageSize" :page-sizes="[10, 20, 50, 100]"></el-pagination>
                <!-- <span>
          <el-input type="text" v-model="page" style="width:5rem"></el-input>
        </span> -->
                <span class="page_btn">跳转</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "pagination",
    data() {
        return {
            pageNo: 1,
            pageCount: 0,
            total: 0,
            page: ''
        };
    },
    props: ["page_No", "total_num", "status", "page_size", "page_count"],
    created() {
        this.pageNo = this.page_No;
        this.total = this.total_num;
        this.pageSize = this.page_size;
        this.pageCount = this.page_count
    },
    watch: {
        total_num(val, oldVal) {
            this.total = val;
        },
        page_No(val, oldVal) {
            this.pageNo = val;
        },
        page_count(val, oldVal) {
            this.pageCount = val
        }
    },
    mounted() { },
    methods: {
        pageNoChange() {
            this.$emit("noChange", this.pageNo);
        },
        handleSizeChange(val) {
            this.$emit("sizeChange", val);
        },

    }
};
</script>
<style  lang="scss">
.pagination {
    position: relative;
    height: 5%;
    padding: 0 1rem;
    flex-shrink: 0;
    .page {
        // width:100%;
    }
    .total_num {
        color: #666;
        font-size: 0.9rem;
    }
    .el-pagination {
        padding: 0;
    }
    .el-pagination .btn-prev,
    .el-pagination .btn-next,
    .el-pagination .el-pager li {
        background: #fff !important;
        border: 1px solid #eee;
        margin: 0 -1px;
        font-size: 0.8rem;
        margin: 0 2px;
        border-radius: 4px;
    }
    .el-pagination .btn-prev:hover,
    .el-pagination .btn-next:hover,
    .el-pagination .el-pager li:hover {
        border: 1px solid #39b7a5;
        color: #39b7a5;
        position: relative;
        z-index: 10;
        box-sizing: border-box;
    }

    .el-pagination .el-pager li {
        color: #999;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #39b7a5 !important;
        color: #fff !important;
    }
    .el-pagination__jump {
        color: #999 !important;
        background: #fff;
        border-radius: 3px;
        // padding: 0 10px;
        margin-left: 10px;
        font-size: 0 !important;
    }
    .el-pagination__editor {
        margin: 0;
        padding: 0;
        border-radius: 4px 0 0 4px;
    }

    .el-pagination__jump .el-input {
        width: 2.5rem;
    }
    .el-pagination__jump .el-pagination__editor .el-input__inner {
        border-radius: 4px 0 0 4px;
    }
    .el-pagination__jump input {
        color: #999 !important;
    }

    .page {
        position: relative;
    }

    .el-pagination button {
        border: 1px solid #eee;
    }
    .el-pagination__jump .el-input__inner,
    .el-pagination__sizes .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        border: 1px solid #fff;
        font-size: 0.8rem;
    }
    .el-input__inner:focus {
        border: 1px solid #39b7a5;
    }
    .el-input__inner:hover {
        border: 1px solid #39b7a5;
    }
    .el-pagination__sizes {
        margin-right: 5px;
    }
    .el-pagination__sizes .el-input__icon {
        line-height: 28px !important;
    }
    .el-pagination__sizes .el-input__inner:focus {
        border: 1px solid #39b7a5;
    }
    .el-select-dropdown__item.selected {
        color: #39b7a5;
        font-weight: bold;
    }

    .page_btn {
        background: #fff;
        color: #666;
        font-size: 0.8rem;
        height: 28px;
        width: 3rem;
        border-radius: 0 4px 4px 0;
        line-height: 28px;
        cursor: pointer;
        margin-left: -1px;
        text-align: center;
    }
    .page_btn:hover {
        border: 1px solid #39b7a5;
        color: #39b7a5;
        position: relative;
        z-index: 10;
    }
}
.el-select-dropdown__item {
    font-size: 0.8rem;
}
</style>