import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);
//持久化设定
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer(val) {
        return {
            // 只储存state中的值
            menuList: val.menuList,
            rightList:val.rightList,
            indexMangeTabList:val.indexMangeTabList,
            areaList:val.areaList,
        }
    }
});
let store = new Vuex.Store({
    state: {
        roleList: [
            {
                type: 'page',
                menuName: '客户管理',
                pathName: 'clientMange',
                path: 'clientMange',
                component: 'userMange/clientMange',
                apiUrl:'/user/list',
                children: [{
                    rightsName:'详情',
                    rightsKey:'view',
                    apiUrl:'/user/detail',
                    path:'clientInfo'
                },{
                    rightsName:'发放优惠券',
                    rightsKey:'getCoupon',
                    apiUrl:'',
                }],
            }, {
                type: 'page',
                menuName: '服务提供者',
                pathName: 'serviceMange',
                path: 'serviceMange',
                component: 'userMange/serviceMange',
                rights: [
                    
                ]
            }, {
                type: 'page',
                menuName: '商品类目',
                pathName: 'categoryMange',
                path: 'categoryMange',
                component: 'userMange/categoryMange',
                rights: ['add', 'edit', 'del']
            }, {
                type: 'page',
                menuName: '类目参数',
                pathName: 'categoryParams',
                path: 'categoryParams',
                component: 'userMange/categoryParams',
                rights: ['edit', 'del']
            }, {
                type: 'page',
                menuName: '商品管理',
                pathName: 'productMange',
                path: 'productMange',
                component: 'userMange/productMange',
                rights: ['start', 'stop', 'view', 'edit', 'del']
            }, {
                type: 'page',
                menuName: '问答管理',
                pathName: 'questionMange',
                path: 'questionMange',
                component: 'userMange/questionMange',
                rights: ['start', 'stop', 'view', 'edit', 'del']
            }
        ],
        menuList:[],
        rightList:[],
        indexMangeTabList:[],
        messageNum: '', //消息
        areaList:[],
    },
    getters: {
        getMenuInfo(state){
            return state.menuList
        },
         getRightList(state){
            return state.rightList
        },
        getMessageInfo(state) {
            return state.messageNum
        },
        getIndexMangeTab(state) {
            return state.indexMangeTabList
        },
        getAreaList(state) {
            return state.areaList
        },

    },
    mutations: {
        setmessageInfo(state, messageNum) {
            state.messageNum = messageNum
        },
        setMenuList(state,menuList){
            state.menuList = menuList
        },
        setRightList(state,rightList){
            state.rightList = rightList
        },
        setIndexMangeTabList(state,indexMangeTabList){
            state.indexMangeTabList = indexMangeTabList
        },
        setAreaList(state,areaList){
            state.areaList = areaList
        },

    },
    actions: {
        // 第一个参数为对象，与store 实例具有相同方法和属性的 context 对象，第二个参数与mutation一样
        setMessageAction({ commit }, payload) {
            commit("setmessageInfo", payload)
        },
        setMenuAction({ commit }, payload) {
            commit("setMenuList", payload)
        },
        setRightAction({ commit }, payload) {
            commit("setRightList", payload)
        },
        setIndexMangeTabAction({ commit }, payload) {
            commit("setIndexMangeTabList", payload)
        },
        setAreaAction({ commit }, payload) {
            commit("setAreaList", payload)
        }
    },
    plugins: [vuexLocal.plugin], //持久化插件  npm install vuex-persist -D
});

export default store;