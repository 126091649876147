import config from './config';
import axios from 'axios';
import qs from 'qs';
import router from '../router/index'
// axios 配置
axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;
// 定义头部 企业端 token获取
// var mertoken = getCookie('paltform_token');
// axios.defaults.headers.common['paltform_token'] = mertoken ;
//Axios实现请求重试
axios.defaults.retry = 3; //重试次数
axios.defaults.retryDelay = 1000; //重试延时
axios.defaults.shouldRetry = (error) => true; //重试条件，默认只要是错误都需要重试
/**
 * 创建axios对象
 **/
let axiosInstance = axios.create({
    baseURL: config.baseURL,
    withCredentials: true,
});
// /**
//  * 访问请求拦截(在请求前处理)
//  **/
axiosInstance.interceptors.request.use(
    function (config) {
        //企业端token
        var mertoken = getCookie('platform_token');
        axios.defaults.headers.common['platform_token'] = mertoken;
        config.headers.common['platform_token'] = mertoken;
        return config;
    },
    function (error) {
        return Promise.reject(error)
    }
);

/**
 * 响应请求拦截(在响应前处理)
 **/
axiosInstance.interceptors.response.use(
    function (response) {
        if (response.data.resultCode == 10000) {
            return response;
        } else if (response.data.resultCode == 21001||response.data.resultCode==1017) {
            return response;
        }  else if (response.data.resultCode == 210016) {
            return response;
        }else if (response.data.resultCode == 210043||response.data.resultCode == 210049) {
            return response;
        }else if (response.data.resultCode == 21005) {
            // // 跳转至login
            router.push({
                name: "error"
            });
        } else if (response.data.resultCode == 310015) {
            // // 跳转至login
            router.push({
                name: "error"
            });
        }else if (response.data.resultCode == 10002) {
            // // 跳转至login
            router.push({
                name: "limitedError"
            });
        }
        return response;
    },
    function (error) {
        if (error.response) {
            let status = error.response.status;
            switch (status) {
                case 401:
                    // 跳转至login
                    router.push({
                        name: "login"
                    });
                    break;
            }
        }
        //处理报错 记录日志
        if (error !== undefined) {

        }

    }
);

/**
 * http请求响应处理函数
 **/
let httpResponseHandle = function () {
    let self = this;
    let res = self.res;
    if (res.resultCode == 10000) {
        self.successCallback && self.successCallback(res);
    } else if (res.resultCode == 21005) {
        // 清除token
        // 跳转至login
        router.push({
            name: 'error'
        })
    } else {
        // 统一错误弹出
        self.failCallback && self.failCallback(res);
    }
};


let http = {
    /**
     * 以get方式请求获取JSON数据
     * @param {Object} opts 配置项，可以包含以下成员：
     * @param {String} opts.url 请求地址
     * @param {Object} opts.params 附加的请求参数
     * @param {Function} opts.successCallback 成功的回调函数
     * @param {Function} opts.failCallback 失败的回调函数
     * **/
    get: function (opts) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .get(opts.url, { params: opts.params })
                .then(function (res) {
                    opts.res = res.data;
                    httpResponseHandle.call(opts);
                })
                .catch(function (err) {
                    if (err.response) {
                        if (err.response.data) {
                            opts.res = err.response.data;
                            httpResponseHandle.call(opts);
                            resolve(res.data);
                        } else {
                            // 统一错误弹出 统一错误页面
                            router.push({
                                name: '/error'
                            })
                        }
                    }
                });
        })
    },

    /**
     * 以post方式请求获取JSON数据
     * @param {Object} opts 配置项，可以包含以下成员：
     * @param {String} opts.url 请求地址
     * @param {Object} opts.params 附加的请求参数
     * @param {Function} opts.successCallback 成功的回调函数
     * @param {Function} opts.failCallback 失败的回调函数
     * **/
    post: function (opts) {
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(opts.url, opts.params)
                .then(function (res) {
                    if (res == undefined) {
                        router.push({
                            path: '/error'
                        })
                    }
                    var errCode = res.data.resultCode;
                    opts.res = res.data;
                    // httpResponseHandle.call(opts);
                   
                   
                    if (errCode == 10000 || errCode == 320001 || errCode == 320002 || errCode == 21001 || errCode == 21002 || errCode == 21003 || errCode == 1015 || errCode == 1017 || errCode == 210033 || errCode == 210040 || errCode == 210036|| errCode == 210037|| errCode == 210039||errCode == 21006||errCode ==210016||errCode == 210032||errCode ==210033||errCode ==210043||errCode ==210049||errCode ==210050||errCode ==210052) {
                        console.log(errCode)
                        resolve(res.data);
                    } else if (errCode == 21005 || errCode == 10001 || errCode == 10002 || errCode == 10003) {
                        //未登陆
                        router.push({
                            path: '/login'
                        })
                    } else if (errCode == 10013) {
                        //参数错误
                        router.push({
                            path: '/error'
                        })
                    } else if (errCode == 19999 || errCode == 19997 || errCode == 10004 || errCode == 10005 || errCode == 10006 || errCode == 10007 || errCode == 10008 || errCode == 10009 || errCode == 10010 || errCode == 10011 || errCode == 10012 || errCode == 10013 || errCode == 10019 || errCode == 10020 || errCode == 10009) {
                        //系统异常
                        router.push({
                            path: '/error'
                        })
                    }
                })
                .catch(function (err) {
                    if (err.response) {
                        if (err.response.data) {
                            console.log(err)
                            opts.res = err.response.data;
                            // httpResponseHandle.call(opts);
                        } else {
                            // 统一错误弹出
                            router.push({
                                path: '/error'
                            })
                        }
                    }
                });
        })
    }
};
export default http;






export function getCookie(c_name) {

    //判断document.cookie对象里面是否存有cookie
    if (document.cookie.length > 0) {

        var c_start = document.cookie.indexOf(c_name + "=")
        //如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1
            var c_end = document.cookie.indexOf(";", c_start)
            if (c_end == -1) c_end = document.cookie.length
            return unescape(document.cookie.substring(c_start, c_end))
        }
    }
    return ""
}
/* 封装get方法*/
export function get(url, data) {
    var url = config.baseURL + url;
    return new Promise((resolve, reject) => {
        axios.get(url, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            })
    })
}

/* 封装post方法 */
export function post(url, data) {
    var url = config.baseURL + url;
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                if (res.data.resultCode == 21005) {
                    this.$router.push({
                        path: '/login'
                    })
                }
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            })
    })
}

/* 封装文件/图片上传方法 */
export function upload(data) {
    var url = config.baseURL + '/api/common/img/upload';
    return new Promise((resolve, reject) => {
        var fileData = new FormData();
        fileData.append("imageUrl", data);
        var options = { // 设置axios的参数
            url: url,
            data: fileData,
            method: 'post',
            timeout:60000,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        axios(options)
            .then((res) => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data);
            })
    })
}