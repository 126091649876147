import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/base.css'
import './assets/table.scss'
import './assets/form.scss'
import './assets/productEdit.scss'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import pagination from './components/pagination';
import confirmTip from './components/confirmTip';
import errorTip from './components/errorTip';
import dispatch from './components/dispatch';
import { post, get, upload } from './http/http';
import api from './http/api';
import axios from "axios";
import store from './store/index.js';
import { VueJsonp } from 'vue-jsonp'




import getDate from './utils/getDate';
import DivScrooll from './utils/scroll';
import getTableHeight from './utils/tableHeight'
//import permission from'./utils/permission'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(pagination);
Vue.use(confirmTip);
Vue.use(errorTip);
Vue.use(dispatch);
Vue.use(api); //api 函数
Vue.use(VueJsonp)
// Vue.use(permission)


Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$upload = upload;
Vue.prototype.$getDate = getDate;
Vue.prototype.$DivScrooll = DivScrooll;
Vue.prototype.$getTableHeight = getTableHeight;
Vue.prototype.$axios = axios;

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
  });

// const whiteList = ['/login', '/error', '/']
// router.beforeEach((to, from, next) => {
//     if (whiteList.includes(to.path)) {
//         next()
//     } else {
//         let rightList = store.state.roleList
//         console.log(rightList)
//         console.log(to.path)
//         let res = rightList.some(function (item, index) {
//             return to.path.indexOf(item.path) > -1
//         });
//         console.log(res)
//         if (res) {

//             next()
//         } else {
//             router.push({
//                 path: '/error'
//             })
//         }

//     }
// })

Vue.directive('premission', {
    inserted: function (el, binding) {
        const path = binding.value.path
        const action = binding.value.action
        //console.log(action)

        let rightList = store.state.rightList
        let buttonList = []
        let reuslt = false
        rightList.forEach(item => {
            if (item.hrefUrl == path) {
                item.buttonList.forEach(bItem => {
                    buttonList.push(bItem.buttonName)
                })
            }
        })
        if (buttonList) {
            if (buttonList.indexOf(action) == -1) {
                el.style.display = 'none'
            }
        }else{
            el.style.display = 'none'
        }





        // const currentRight=router.currentRoute.meta
        // if(currentRight){
        //     if(currentRight.indexOf(action)==-1){
        //         const type=binding.value.effect
        //         if(type=='disabled'){
        //             el.disabled=true
        //             el.classList.add('is-disabled')
        //         }else {
        //             el.parentNode.removeChild(el)
        //         }
        //     }
        // }

    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')