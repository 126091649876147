import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '*',
    redirect: '/'
},
{
    path: '/',
    redirect: '/login'
}, {
    path: '/login',
    name: 'login',
    component: () =>
        import('../pages/login.vue')
},

{
    path: '/error',
    name: 'error',
    component: () =>
        import('../pages/error.vue')
},

//服务提供者管理
{
    path: '/serviceMange',
    name: 'serviceMange',
    component: () =>
        import('../pages/userMange/serviceMange.vue')
},

// 客户管理
{
    path: '/clientMange',
    name: 'clientMange',
    component: () =>
        import('../pages/userMange/clientMange.vue')
},
// 客户管理
{
    path: '/clientInfo/:editId',
    name: 'clientInfo',
    component: () =>
        import('../pages/userMange/clientInfo.vue')
},
// 商品类目
{
    path: '/categoryMange',
    name: 'categoryMange',
    component: () =>
        import('../pages/productMange/categoryMange.vue')
},
// 类目参数
{
    path: '/categoryParams',
    name: 'categoryParams',
    component: () =>
        import('../pages/productMange/categoryParams.vue')
},
//编辑类目参数
{
    path: '/editCategoryParams/:argumentId',
    name: 'editCategoryParams',
    component: () =>
        import('../pages/productMange/editCategoryParams.vue')
},
// 商品管理
{
    path: '/productMange',
    name: 'productMange',
    component: () =>
        import('../pages/productMange/productMange.vue')
},
//新建商品
{
    path: '/editProductBase/:productId/:editType',
    name: 'editProductBase',
    component: () =>
        import('../pages/productMange/editProductBase.vue')
},
//商品详情
{
    path: '/editProductDetail/:productId/:editType',
    name: 'editProductDetail',
    component: () =>
        import('../pages/productMange/editProductDetail.vue')
},
//问答管理
{
    path: '/questionMange',
    name: 'questionMange',
    component: () =>
        import('../pages/productMange/questionMange.vue')
},
//问答详情
{
    path: '/questionDetail/:productQuestionId',
    name: 'questionDetail',
    component: () =>
        import('../pages/productMange/questionDetail.vue')
},
//订单管理
{
    path: '/orderMange',
    name: 'orderMange',
    component: () =>
        import('../pages/tradeMange/orderMange.vue')
},
//订单详情
{
    path: '/orderDetail/:orderId',
    name: 'orderDetail',
    component: () =>
        import('../pages/tradeMange/orderDetail.vue')
},
//售后单管理
{
    path: '/afterSalesMange',
    name: 'afterSalesMange',
    component: () =>
        import('../pages/tradeMange/afterSalesMange.vue')
},
//售后单详情
{
    path: '/afterSalesDetail/:orderRefundId',
    name: 'afterSalesDetail',
    component: () =>
        import('../pages/tradeMange/afterSalesDetail.vue')
},
// 服务单管理
{
    path: '/serviceOrderMange',
    name: 'serviceOrderMange',
    component: () =>
        import('../pages/tradeMange/serviceOrderMange.vue')
},
//服务单详情
{
    path: '/serviceOrderDetail/:orderReservationId',
    name: 'serviceOrderDetail',
    component: () =>
        import('../pages/tradeMange/serviceOrderDetail.vue')
},
//服务改派管理
{
    path: '/serviceOrderChangeMange',
    name: 'serviceOrderChangeMange',
    component: () =>
        import('../pages/tradeMange/serviceOrderChangeMange.vue')
},
//服务改派单详情
{
    path: '/serviceOrderChangeDetail/:orderReservationResendId',
    name: 'serviceOrderChangeDetail',
    component: () =>
        import('../pages/tradeMange/serviceOrderChangeDetail.vue')
},
//服务单策略
{
    path: '/serviceOrderPlan',
    name: 'serviceOrderPlan',
    component: () =>
        import('../pages/plan/serviceOrderPlan.vue')
},
//服务单半径策略
{
    path: '/serviceRadiusPlan',
    name: 'serviceRadiusPlan',
    component: () =>
        import('../pages/plan/serviceRadiusPlan.vue')
},
//多人拼团
{
    path: '/multiplayer',
    name: 'multiplayer',
    component: () =>
        import('../pages/sale/multiplayer.vue')
},
//新建多人拼团
{
    path: '/editMultiplayer/:collectiveId',
    name: 'editMultiplayer',
    component: () =>
        import('../pages/sale/editMultiplayer.vue')
},
//查看拼团信息
{
    path: '/multiplayerInfo/:collectiveId',
    name: 'multiplayerInfo',
    component: () =>
        import('../pages/sale/multiplayerInfo.vue')
},
//优惠券
{
    path: '/coupon',
    name: 'coupon',
    component: () =>
        import('../pages/sale/coupon.vue')
},
//优惠券
{
    path: '/editCoupon/:editId/:type',
    name: 'editCoupon',
    component: () =>
        import('../pages/sale/editCoupon.vue')
},
//自动发放优惠
{
    path: '/autoCoupon',
    name: 'autoCoupon',
    component: () =>
        import('../pages/sale/autoCoupon.vue')
},
//手动发放记录
{
    path: '/controlCoupon',
    name: 'controlCoupon',
    component: () =>
        import('../pages/sale/controlCoupon.vue')
},
//手动发放记录
{
    path: '/editControlCoupon',
    name: 'editControlCoupon',
    component: () =>
        import('../pages/sale/editControlCoupon.vue')
},
//服务模板管理
{
    path: '/serviceTemplate',
    name: 'serviceTemplate',
    component: () =>
        import('../pages/service/serviceTemplate.vue')
},
//添加服务模板
{
    path: '/editServiceTemplate/:serviceTemplateId',
    name: 'editServiceTemplate',
    component: () =>
        import('../pages/service/editServiceTemplate.vue')
},
//结算订单明细
{
    path: '/accountOrder',
    name: 'accountOrder',
    component: () =>
        import('../pages/detail/accountOrder.vue')
},
//结算售后明细
{
    path: '/accountAfterSale',
    name: 'accountAfterSale',
    component: () =>
        import('../pages/detail/accountAfterSale.vue')
},
//结算发票明细
{
    path: '/accountInvoice',
    name: 'accountInvoice',
    component: () =>
        import('../pages/detail/accountInvoice.vue')
},

//首页管理
{
    path: '/indexMange',
    name: 'indexMange',
    component: () =>
        import('../pages/content/indexMange.vue'),
    children: [
        //首页轮播图管理
        {
            path: 'indexBannerMange',
            name: 'indexBannerMange',
            component: () =>
                import('../pages/content/indexBannerMange.vue')
        },
        //首页商品管理
        {
            path: 'indexCategoryMange',
            name: 'indexCategoryMange',
            //component: productBase
            component: () =>
                import('../pages/content/indexCategoryMange'),
        },
        //首页文章管理
        {
            path: 'indexNewsMange',
            name: 'indexNewsMange',
            component: () =>
                import('../pages/content/indexNewsMange.vue')
        },
        //首页轮播配置
        {
            path: 'indexBannerSet',
            name: 'indexBannerSet',
            //component: productBase
            component: () =>
                import('../pages/content/indexBannerSet'),
        },
        //首页弹窗
        {
            path: 'indexAlertMange',
            name: 'indexAlertMange',
            component: () =>
                import('../pages/content/indexAlertMange.vue')
        },
         //分类管理
         {
            path: 'groupMange',
            name: 'groupMange',
            component: () =>
                import('../pages/content/groupMange.vue')
        },
    ]
},
//首页橱窗
{
    path: '/indexProductWindow/:indexCategoryId/:categoryName',
    name: 'indexProductWindow',
    component: () =>
        import('../pages/content/indexProductWindow.vue')
},
//分类商品橱窗
{
    path: '/groupProductWindow/:indexCategoryId/:categoryName',
    name: 'groupProductWindow',
    component: () =>
        import('../pages/content/groupProductWindow.vue')
},
//评价管理
{
    path: '/evaluateMange',
    name: 'evaluateMange',
    component: () =>
        import('../pages/tradeMange/evaluateMange.vue')
},
//消息中心
{
    path: '/message',
    name: 'message',
    component: () =>
        import('../pages/message.vue')
},
//文章管理
{
    path: '/articleMange',
    name: 'articleMange',
    component: () =>
        import('../pages/content/articleMange.vue')
},
//编辑文章管理
{
    path: '/editArticle/:articleId',
    name: 'editArticle',
    component: () =>
        import('../pages/content/editArticle.vue')
},
//h5详情
{
    path: '/h5Detail/:articleId',
    name: 'h5Detail',
    component: () =>
        import('../pages/h5Detail.vue')
},
//全民营销规则管理
{
    path: '/saleRule',
    name: 'saleRule',
    component: () =>
        import('../pages/sale/saleRule.vue')
},
//好友助力领券包
{
    path: '/saleCouponMange',
    name: 'saleCouponMange',
    component: () =>
        import('../pages/sale/saleCouponMange.vue')
},
//编辑好友助力领券包
{
    path: '/editSaleCoupon/:editId/:editType',
    name: 'editSaleCoupon',
    component: () =>
        import('../pages/sale/editSaleCoupon.vue')
},
// 运营账号管理
{
    path: '/operateMange',
    name: 'operateMange',
    component: () =>
        import('../pages/system/operateMange.vue')
},
// 角色管理
{
    path: '/roleMange',
    name: 'roleMange',
    component: () =>
        import('../pages/system/roleMange.vue')
},
// 编辑角色管理
{
    path: '/editRole/:editId/:editType',
    name: 'editRole',
    component: () =>
        import('../pages/system/editRole.vue')
},
//权限错误
{
    path: '/limitedError',
    name: 'limitedError',
    component: () =>
        import('../pages/limitedError.vue')
},
// 佣金账户
{
    path: '/commission',
    name: 'commission',
    component: () =>
        import('../pages/detail/commission.vue')
},
//佣金订单
{
    path: '/commissionOrder/:editId',
    name: 'commissionOrder',
    component: () =>
        import('../pages/detail/commissionOrder.vue')
},
//佣金明细
{
    path: '/commissionDetail/:editId',
    name: 'commissionDetail',
    component: () =>
        import('../pages/detail/commissionDetail.vue')
},
{
    path: '/officalAccount',
    name: 'officalAccount',
    component: () =>
      import('../pages/officalAccount'),
      meta:{
        title:'绿城房屋4S'
      }
  },
]

const router = new VueRouter({
    routes
})

export default router

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}